<template>
  <div class="lockedBox">
    <NavBar title="锁仓" :left-arrow="false">
      <template #right>
        <TextBox :font-size="26" @click.native="goOrder">我的订单</TextBox>
      </template>
    </NavBar>
    <div class="contentBox">
      <Space :height="30"></Space>
      <van-image
        :src="require('@/assets/images/locked/banner.png')"
        class="radius"
        fit="cover"
        :height="$pxToVw(290)"
      />
      <Space :height="30"></Space>
      <van-swipe :style="{ height: $pxToPxRatio(40) + 'px' }" vertical :show-indicators="false" autoplay="3000">
        <van-swipe-item v-for="(item, index) of noticeList" :key="index">
          <div class="messageBox van-ellipsis">
            <div class="van-ellipsis">{{ item.value }}</div>
            <div>{{ item.time }}</div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <Space :height="30"></Space>
      <ListView :get-list="getActivityList" empty="暂无活动" pull-refresh>
        <template v-slot="{ data }">
          <template v-for="(item, index) of data">
            <div class="listBox" :key="index">
              <div class="titleBox">
                <img :src="item.icon" alt="" class="icon" />
                <div class="title">{{ item.title }}</div>
              </div>
              <div class="bottomBox">
                <div class="textBox">
                  <div>
                    <div>{{ item.radio }}</div>
                    <div>预计年化</div>
                  </div>
                  <div>
                    <div>{{ item.time }}</div>
                    <div>锁仓周期</div>
                  </div>
                </div>
                <van-button block type="primary" class="radius" :disabled="!item.active" @click="goDig(item.id)">{{
                  item.active ? '立即锁仓' : '已结束'
                }}</van-button>
              </div>
            </div>
            <Space :height="30" :key="`space${index}`"></Space>
          </template>
        </template>
      </ListView>
    </div>
    <TabBar :active="2"></TabBar>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {
      noticeList: [],
    };
  },
  created() {
    this.getUserRecordList();
  },
  mounted() {
    this.$store.commit('setBodyColor');
  },
  methods: {
    getUserRecordList() {
      this.$http('get', '/v1/locked/LockedAUserRecord').then(res => {
        this.noticeList = res.data.map(item => {
          return {
            value: `用户${this.$formatPhone(item.phone)}，锁仓${item.lockedCoin}${item.closeCoinName}`,
            time: this.$dayjs(item.createTime).format('YYYY/MM/DD'),
          };
        });
      });
    },
    async getActivityList(page) {
      let res = await this.$http('post', '/v1/locked/LockedActivityRecord', {
        page: page,
        size: 4,
      });

      let statusProcess = res => {
        switch (res) {
          case 0:
            return false;
          case 1:
            return true;
        }
      };
      res.data.list = res.data.list.map(item => {
        return {
          id: item.id,
          icon: require('@/assets/images/locked/1.png'),
          title: item.title,
          radio: `${this.$accMul(item.apr, 100)}%`,
          time: `${item.day}天`,
          active: statusProcess(item.status),
        };
      });
      return res;
    },
    goOrder() {
      this.$router.push('/locked/order?i=1');
    },
    goDig(id) {
      this.$router.push(`/locked/dig?id=${id}`);
    },
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.messageBox {
  display: flex;
  justify-content: space-between;
  color: @text-color1;
}
.listBox {
  .radius;
  background: @white;
  .titleBox {
    height: 106px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @line-color1;
    padding: 0 26px;
    .icon {
      width: 56px;
      height: 56px;
      margin-right: 16px;
    }
    .title {
      font-size: 32px;
      color: @title-color;
    }
  }
  .bottomBox {
    padding: 0 95px 32px;
    .textBox {
      height: 164px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        &:first-child {
          div {
            &:first-child {
              color: @text-color3;
            }
          }
        }
        div {
          &:first-child {
            font-size: 32px;
            font-weight: bold;
            color: @title-color;
            text-align: center;
          }
          &:last-child {
            color: @intro-color;
            font-size: 24px;
            margin-top: 13px;
          }
        }
      }
    }
  }
}
</style>
